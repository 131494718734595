import { FlopyModflowMfchd, FlopyModflowMfdis } from '../../../../../../core/model/flopy/packages/mf';
import { Form, Grid, Header, Input, Label, Segment } from 'semantic-ui-react';
import { GridSize } from '../../../../../../core/model/modflow';
import { IFlopyModflowMfchd } from '../../../../../../core/model/flopy/packages/mf/FlopyModflowMfchd';
import { cloneDeep } from 'lodash';
import { documentation } from '../../../../defaults/flow';
import FlopyModflow from '../../../../../../core/model/flopy/packages/mf/FlopyModflow';
import InfoPopup from '../../../../../shared/InfoPopup';
import RasterDataImage from '../../../../../shared/rasterData/rasterDataImage';
import React, { ChangeEvent, useState } from 'react';

interface IProps {
  mfPackage: FlopyModflowMfchd;
  mfPackages: FlopyModflow;
  onChange: (pck: FlopyModflowMfchd) => void;
  readonly: boolean;
}

const ChdPackageProperties = (props: IProps) => {
  const [mfPackage, setMfPackage] = useState<IFlopyModflowMfchd>(props.mfPackage.toObject());
  const { mfPackages, readonly } = props;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    return setMfPackage({ ...mfPackage, [name]: value });
  };

  const handleOnBlur = (cast?: (v: any) => any) => (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    let { value } = e.target;

    if (cast) {
      value = cast(value);
    }

    setMfPackage({ ...mfPackage, [name]: value });
    props.onChange(FlopyModflowMfchd.fromObject({ ...mfPackage, [name]: value }));
  };

  if (!mfPackage) {
    return null;
  }

  const disPackage: FlopyModflowMfdis = mfPackages.getPackage('dis') as FlopyModflowMfdis;

  const emptyValues: Array<Array<Array<number | undefined>>> = [];
  for (let l = 0; l < disPackage.nlay; l++) {
    emptyValues[l] = [];
    for (let r = 0; r < disPackage.nrow; r++) {
      emptyValues[l][r] = [];
      for (let c = 0; c < disPackage.ncol; c++) {
        emptyValues[l][r][c] = undefined;
      }
    }
  }

  const sHeadValues = cloneDeep(emptyValues);
  const eHeadValues = cloneDeep(emptyValues);

  if (mfPackage.stress_period_data) {
    Object.values(mfPackage.stress_period_data)[0].forEach((spv: number[]) => {
      const [lay, row, col, shead, ehead] = spv;
      sHeadValues[lay][row][col] = shead;
      eHeadValues[lay][row][col] = ehead;
    });
  }

  return (
    <Form>
      <Header as={'h3'} dividing={true}>CHD: Constant Head Package</Header>
      <Grid divided={'vertically'}>
        <Grid.Row columns={2}>
          {sHeadValues.map((layer: any, idx) => (
            <Grid.Column key={idx}>
              <Label>SHead Layer {idx + 1} SP1</Label>
              <RasterDataImage
                data={layer}
                gridSize={GridSize.fromData(layer)}
                unit={''}
                border={'1px dotted black'}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      <Grid divided={'vertically'}>
        <Grid.Row columns={2}>
          {eHeadValues.map((layer: any, idx) => (
            <Grid.Column key={idx}>
              <Label>EHead Layer {idx + 1} SP1</Label>
              <RasterDataImage
                data={layer}
                gridSize={GridSize.fromData(layer)}
                unit={''}
                border={'1px dotted black'}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      <Segment>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <label>Filename extension (EXTENSION)</label>
            <Input
              readOnly={readonly}
              name={'extension'}
              value={mfPackage.extension}
              onChange={handleOnChange}
              onBlur={handleOnBlur(parseFloat)}
              icon={<InfoPopup description={documentation.chd.extension} title={'EXTENSION'} />}
            />
          </Form.Field>
          <Form.Field>
            <label>File unit number (UNITNUMBER)</label>
            <Input
              readOnly={readonly}
              type={'number'}
              name={'unitnumber'}
              value={mfPackage.unitnumber || ''}
              onChange={handleOnChange}
              onBlur={handleOnBlur(parseFloat)}
              icon={<InfoPopup description={documentation.chd.unitnumber} title={'UNITNUMBER'} />}
            />
          </Form.Field>
          <Form.Field>
            <label>Filenames (FILENAMES)</label>
            <Input
              readOnly={readonly}
              name={'filenames'}
              value={mfPackage.filenames || ''}
              onChange={handleOnChange}
              onBlur={handleOnBlur(parseFloat)}
              icon={<InfoPopup description={documentation.chd.filenames} title={'FILENAMES'} />}
            />
          </Form.Field>
        </Form.Group>
      </Segment>
    </Form>
  );
};

export default ChdPackageProperties;
