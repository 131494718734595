import { AdvancedCsvUpload } from '../../../../shared/upload';
import { Boundary, FlowAndHeadBoundary, HeadObservationWell } from '../../../../../core/model/modflow/boundaries';
import { Button, Checkbox, Icon, Input, InputOnChangeData, InputProps, Label, Table } from 'semantic-ui-react';
import { ChangeEvent, FormEvent, useState } from 'react';
import { ISpValues } from '../../../../../core/model/modflow/boundaries/Boundary.type';
import { Stressperiods } from '../../../../../core/model/modflow';
import { cloneDeep } from 'lodash';
import BoundaryDateTimeImporter from './boundaryDateTimeImporter';
import moment, { DurationInputArg1, DurationInputArg2 } from 'moment';

interface IActiveInput {
  col: number;
  name: string;
  row: number;
  value: string;
}

interface IActiveString {
  idx: number;
  value: string;
}

interface IProps {
  boundary: HeadObservationWell | FlowAndHeadBoundary;
  isScenario: boolean;
  onChange: (boundary: Boundary) => any;
  readOnly: boolean;
  selectedOP?: string;
  stressperiods: Stressperiods;
}

const BoundaryDateTimeValuesDataTable = (props: IProps) => {
  const [activeInput, setActiveInput] = useState<IActiveInput | null>(null);
  const [activeDateTime, setActiveDateTime] = useState<IActiveString | null>(null);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

  const { boundary, selectedOP } = props;

  const getSpValues = () => {
    if (boundary instanceof FlowAndHeadBoundary) {
      return selectedOP ? boundary.getSpValues(props.stressperiods, selectedOP) : null;
    }
    return boundary.getSpValues(props.stressperiods);
  };

  const spValues: ISpValues | null = getSpValues();

  const handleChangeDateTime = () => {
    if (activeDateTime) {
      props.onChange(boundary.changeDateTime(activeDateTime.value, activeDateTime.idx, selectedOP));
    }
    return setActiveDateTime(null);
  };

  const handleLocalChangeDateTime = (e: ChangeEvent<HTMLInputElement>, { value, idx }: InputOnChangeData) =>
    setActiveDateTime({
      idx,
      value,
    });

  const handleToggleUploadModal = () => setShowUploadModal(!showUploadModal);

  const handleLocalChange = (row: number, col: number) => (e: ChangeEvent<HTMLInputElement>) =>
    setActiveInput({
      col,
      name: e.target.name,
      row,
      value: e.target.value,
    });

  const handleSpValuesChange = () => {
    if (!activeInput) {
      return;
    }
    const { value, row, col } = activeInput;
    setActiveInput(null);

    if (spValues) {
      const updatedSpValues = boundary.getSpValues(props.stressperiods, selectedOP).map((spv, spvIdx) => {
        const newRow = cloneDeep(spv);
        if (row === spvIdx) {
          newRow[col] = parseFloat(value) || 0;
          return newRow;
        }
        return newRow;
      });
      boundary.setSpValues(updatedSpValues as ISpValues, selectedOP);
    }
    return props.onChange(boundary);
  };

  const handleImportCsv = (data: any[][]) => {
    const fData = cloneDeep(data).map((row) => {
      return row.splice(1, boundary.valueProperties.length);
    });
    const dateTimes = data.map((row) => {
      if (moment.isMoment(row[0])) {
        return row.shift();
      }
      return moment();
    });

    if (boundary instanceof FlowAndHeadBoundary && selectedOP) {
      boundary.updateDateTimeValues(selectedOP, fData, dateTimes);
      return props.onChange(boundary);
    }

    if (boundary instanceof HeadObservationWell) {
      boundary.dateTimes = dateTimes;
      boundary.setSpValues(fData);
      return props.onChange(boundary);
    }
  };

  const handleBlurPercentage = (id: number) => () => {
    if (!activeInput) {
      return null;
    }

    const cSpValues = getSpValues()?.map((row) => {
      let value = row[id];
      if (typeof value === 'number') {
        value += (value * parseFloat(activeInput.value)) / 100;
      }

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          for (let j = 0; j < value[i].length; j++) {
            value[i][j] += (value[i][j] * parseFloat(activeInput.value)) / 100;
          }
        }
      }

      row[id] = value;
      return row;
    });

    if (cSpValues) {
      boundary.setSpValues(cSpValues as ISpValues, selectedOP);
      setActiveInput(null);
      return props.onChange(boundary);
    }
  };

  const handleChangePercentage = (e: FormEvent<HTMLInputElement>, { name, value }: InputProps) =>
    setActiveInput({ col: -1, name, row: -1, value });

  const handleAddDateTime = (value: DurationInputArg1, unit: DurationInputArg2) => () => {
    return props.onChange(boundary.addDateTime(value, unit, selectedOP, props.stressperiods));
  };

  const handleRemoveDateTime = (idx: number) => () => {
    props.onChange(boundary.removeDateTime(idx, selectedOP));
  };

  const handleEnableRowsClick = (idx: number) => () => {
    if (boundary instanceof FlowAndHeadBoundary) {
      const rd = cloneDeep(boundary.spValuesEnabled);
      rd[idx] = !rd[idx];
      boundary.spValuesEnabled = rd;
      props.onChange(boundary);
    }
  };

  const getCellStyle = (numberOfCells: number) => {
    switch (numberOfCells) {
      case 2:
        return {
          maxWidth: '130px',
          padding: 0,
          border: 0,
        };
      case 3:
        return {
          maxWidth: '130px',
          padding: 0,
          border: 0,
        };
      default:
        return {
          maxWidth: '150px',
          padding: 0,
          border: 0,
        };
    }
  };

  const body = () => {
    const dateTimes = boundary.getDateTimes(props.stressperiods, selectedOP);

    if (!spValues) {
      return (
        <Table.Row>
          <Table.Cell>There are no stress period values.</Table.Cell>
        </Table.Row>
      );
    }

    return dateTimes.map((dt, spIdx) => (
      <Table.Row key={spIdx}>
        <Table.Cell width={4}>
          <Input
            style={getCellStyle(1)}
            disabled={props.readOnly}
            idx={spIdx}
            name={'dateTime'}
            onBlur={handleChangeDateTime}
            onChange={handleLocalChangeDateTime}
            type={'date'}
            value={
              activeDateTime && activeDateTime.idx === spIdx
                ? activeDateTime.value
                : moment(dateTimes[spIdx]).format('YYYY-MM-DD')
            }
          />
        </Table.Cell>
        {spValues[spIdx].map((v, vIdx) => (
          <Table.Cell key={vIdx}>
            <Input
              style={getCellStyle(spValues[spIdx].length)}
              disabled={props.readOnly || (boundary instanceof FlowAndHeadBoundary && !boundary.spValuesEnabled[vIdx])}
              id={spIdx}
              col={vIdx}
              name={'dateTimeValue'}
              onBlur={handleSpValuesChange}
              onChange={handleLocalChange(spIdx, vIdx)}
              type={'number'}
              value={activeInput && activeInput.col === vIdx && activeInput.row === spIdx ? activeInput.value : v}
            />
          </Table.Cell>
        ))}
        <Table.Cell>
          {!props.readOnly && (
            <Button basic={true} floated={'right'} icon={'trash'} onClick={handleRemoveDateTime(spIdx)} />
          )}
        </Table.Cell>
      </Table.Row>
    ));
  };

  return (
    <div>
      {showUploadModal && (
        <AdvancedCsvUpload
          columns={boundary.valueProperties.map((p, key) => {
            return {
              key: key + 1,
              value: p.name.toLowerCase(),
              text: p.name,
            };
          })}
          onCancel={handleToggleUploadModal}
          onSave={handleImportCsv}
          useDateTimes={true}
        />
      )}
      {!props.readOnly && (
        <p style={{ marginTop: '10px' }}>
          <b>Time dependent boundary values{boundary instanceof FlowAndHeadBoundary ? ' observation point' : ''}</b>
          <Button.Group floated='right' size='mini'>
            <Button icon={true} labelPosition='left' onClick={handleToggleUploadModal} primary={true}>
              <Icon name='upload' />
              Upload csv
            </Button>
            {boundary instanceof HeadObservationWell && (
              <BoundaryDateTimeImporter
                boundary={boundary}
                onChange={props.onChange}
                selectedOP={props.selectedOP}
                stressPeriods={props.stressperiods}
              />
            )}
          </Button.Group>
        </p>
      )}
      <Table size={'small'} singleLine={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Start Date</Table.HeaderCell>
            {boundary.valueProperties.map((p, idx) => (
              <Table.HeaderCell key={idx}>
                {p.name} ({p.unit})
                {p.canBeDisabled && !props.readOnly && (
                  <Checkbox
                    style={{ position: 'relative', marginLeft: '10px' }}
                    checked={boundary instanceof FlowAndHeadBoundary && boundary.spValuesEnabled[idx]}
                    onChange={handleEnableRowsClick(idx)}
                  />
                )}
              </Table.HeaderCell>
            ))}
            {!props.readOnly && <Table.HeaderCell />}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.isScenario && !props.readOnly && (
            <Table.Row>
              <Table.Cell>
                <Label>Change by %</Label>
              </Table.Cell>
              {boundary.valueProperties.map((p, idx) => (
                <Table.Cell key={`percentage_chance_${idx}`} width={2}>
                  <Input
                    label={{ icon: 'percent' }}
                    labelPosition='left corner'
                    name={`percentage_${idx}`}
                    onBlur={handleBlurPercentage(idx)}
                    onChange={handleChangePercentage}
                    placeholder='% Change'
                    type='number'
                    style={getCellStyle(boundary.valueProperties.length)}
                    value={activeInput && activeInput.name === `percentage_${idx}` ? activeInput.value : 0}
                  />
                </Table.Cell>
              ))}
            </Table.Row>
          )}
          {spValues && body()}
        </Table.Body>
      </Table>
      {!props.readOnly && (
        <Button.Group size='small'>
          <Button icon={true} onClick={handleAddDateTime(1, 'days')}>
            <Icon name='add circle' /> 1 Day
          </Button>
          <Button icon={true} onClick={handleAddDateTime(1, 'months')}>
            <Icon name='add circle' /> 1 Month
          </Button>
          <Button icon={true} onClick={handleAddDateTime(1, 'years')}>
            <Icon name='add circle' /> 1 Year
          </Button>
        </Button.Group>
      )}
    </div>
  );
};

export default BoundaryDateTimeValuesDataTable;
